import React from "react";
import { DateTime } from "luxon";
import { connect } from "react-redux";

import {
  APPTS_UPDATE_CONTENT_LOADED,
  NEW_ERRORS,
  APPTS_SHOW_EXPANDED,
  OPEN_CHAT,
} from "patient_app/constants/actionTypes";
import {
  fetchUser,
  fetchProfile,
  fetchCareProvider,
} from "patient_app/api/commonActions";
import {
  fetchAppointments,
  fetchRecoveryGroup,
  fetchNextCourse,
} from "patient_app/api/appointmentActions";
import { fetchHealthInfo } from "patient_app/api/onboardingActions";
import { fetchClinic } from "patient_app/api/clinicActions";
import { fetchActionItems } from "patient_app/api/pendingItemActions";
import { getTrackableProfile } from "patient_app/api/profileActions";
import {
  checkIfQuestionnaireDue,
  checkIfPendingItem,
  getChecklistItems,
} from "patient_app/api/sessionActions";

import ActionItems from "patient_app/views/appointments/partials/ActionItems";
import AppointmentModal from "patient_app/views/appointments/partials/AppointmentModal";
import DashboardBanner from "patient_app/components/utils/DashboardBanner";
import DrugTesting from "patient_app/views/appointments/partials/DrugTesting";
import DrugTestingModal from "patient_app/views/appointments/partials/DrugTestingModal";
import LoadingSpinner from "patient_app/components/utils/LoadingSpinner";
import MessageShortcut from "patient_app/views/appointments/partials/MessageShortcut";
import Nav from "patient_app/components/navbar/Nav";
import NetPromoterScore from "patient_app/components/utils/NetPromoterScore";
import NextCourse from "patient_app/views/appointments/partials/NextCourse";
import PatientChat from "patient_app/components/chat/PatientChat";
import RecoveryGroups from "patient_app/views/appointments/partials/RecoveryGroups";
import Tab from "patient_app/components/tabs/Tab";

import dates from "patient_app/helpers/dates";
import { getUrlVars, getIconUrl } from "patient_app/helpers/linkHelpers";
import permissions from "patient_app/helpers/permissions";
import trackerActions from "patient_app/helpers/trackerActions";
import SessionStore from "patient_app/helpers/SessionStore";

import appointmentTabStyles from "patient_app/stylesheets/components/appointmentTabStyles";
import dashboardBlockStyles from "patient_app/stylesheets/appointments/dashboardBlockStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    checkIfQuestionnaireDue: () => dispatch(checkIfQuestionnaireDue()),
    checkIfPendingItem: () => dispatch(checkIfPendingItem()),
    getChecklistItems: () => dispatch(getChecklistItems()),
    fetchUser: () => dispatch(fetchUser()),
    fetchProfile: (user) => dispatch(fetchProfile(user)),
    fetchHealthInfo: () => dispatch(fetchHealthInfo()),
    fetchClinic: () => dispatch(fetchClinic()),
    fetchCareProvider: () => dispatch(fetchCareProvider()),
    fetchNextCourse: () => dispatch(fetchNextCourse()),
    fetchAppointments: () => dispatch(fetchAppointments()),
    fetchActionItems: (past, userId) =>
      dispatch(fetchActionItems(past, userId)),
    fetchRecoveryGroup: () => dispatch(fetchRecoveryGroup()),
    updateContentLoaded: (loaded) =>
      dispatch({ type: APPTS_UPDATE_CONTENT_LOADED, contentLoaded: loaded }),
    expandAppointment: (appt) =>
      dispatch({ type: APPTS_SHOW_EXPANDED, appt: appt }),
    openChat: (data) => dispatch({ type: OPEN_CHAT, data: data }),
    onErrors: (errors) => dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {
    alcoholCheckDue: state.session.alcoholCheckDue,
    appointments: state.appointments.appointments,
    careProvider: state.common.careProvider,
    chats: state.chat.chats,
    clinic: state.common.clinic,
    contentLoaded: state.appointments.contentLoaded,
    drugTestDetails: state.appointments.drugTestDetails,
    errors: state.common.errors,
    expandedAppt: state.appointments.expandedAppt,
    gutCheckDue: state.session.gutCheckDue,
    hasPendingItem: state.session.hasPendingItem,
    healthInfo: state.onboarding.healthInfo,
    isFetching: state.common.isFetching,
    npsDue: state.session.npsDue,
    nextCourse: state.appointments.nextCourse,
    path: state.session.path,
    paywall: state.common.paywall,
    primaryDiagnosis: state.common.primaryDiagnosis,
    primaryProgram: state.common.primaryProgram,
    profile: state.common.profile,
    recoveryGroup: state.appointments.recoveryGroup,
    success: state.common.success,
    user: state.common.user,
  };
};

class Index extends React.Component {
  showNextAppt = getUrlVars(window.location.href)["next_appointment"];

  componentDidMount = () => {
    document.title = "Appointments | Workit Health";
    if (this.props.user) {
      if (this.props.user.tier === 0) {
        this.props.history.push("/onboarding/freemium_block");
      } else if (this.props.user.locked_out) {
        this.props.history.push("/onboarding/program_description");
      } else {
        this.fetchRelatedInfo();
      }
    } else {
      this.props.fetchUser();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.user && this.props.user) {
      if (this.props.user.tier === 0) {
        this.props.history.push("/onboarding/freemium_block");
      } else if (this.props.user.locked_out) {
        this.props.history.push("/onboarding/program_description");
      } else {
        this.fetchRelatedInfo();
      }
    }

    if (prevProps.user) {
      if (document.referrer.includes("password")) {
        const { profile, user } = prevProps;
        if (!!profile?.id) {
          if (
            ["false", null, undefined].includes(
              SessionStore.get("isSetProfileCalled")
            )
          ) {
            trackerActions.setCustomerUserId(user.id);
            trackerActions.setProfile(
              "TestAccount",
              user?.test_account ? "Yes" : "No"
            );
            getTrackableProfile(profile.id);
            SessionStore.set("isSetProfileCalled", true);
          }
        }
      }
    }

    if (this.props.user && !this.props.user.locked_out) {
      if (!prevProps.hasPendingItem && this.props.hasPendingItem) {
        window.location.href = this.props.path;
      }
    }

    if (
      this.props.appointments &&
      this.props.user &&
      this.props.profile &&
      !this.props.hasPendingItem &&
      !prevProps.contentLoaded
    ) {
      this.props.updateContentLoaded(true);

      if (this.showNextAppt && this.props.appointments.length > 0) {
        const nextAppt = this.props.appointments.find(
          (appt) => DateTime.fromISO(appt.end_at) >= DateTime.local()
        );
        if (!!nextAppt) {
          this.props.expandAppointment(nextAppt);
        }
      }
    }

    if (
      this.props.profile &&
      this.props.paywall &&
      this.props.paywall === true
    ) {
      this.props.history.push(
        `/profiles/${this.props.profile.id}/payment?status=failed&tab=payment_details`
      );
      this.props.onErrors([
        { text: "Please update your payment information to continue." },
      ]);
      return;
    }

    if (!prevProps.chats && this.props.chats) {
      if (getUrlVars(window.location.href)["admin_chat"]) {
        this.props.openChat({ view: "index", topic: "ADMIN" });
      } else if (getUrlVars(window.location.href)["coach_chat"]) {
        this.props.openChat({ view: "index", topic: "COACH" });
      } else if (getUrlVars(window.location.href)["medical_chat"]) {
        this.props.openChat({ view: "index", topic: "MEDICAL" });
      }
    }

    if (!prevProps.recoveryGroup && this.props.recoveryGroup) {
      if (getUrlVars(window.location.href)["support_group"]) {
        this.props.expandAppointment(this.props.recoveryGroup);
      }
    }
  };

  fetchRelatedInfo = () => {
    let { user, profile, clinic, recoveryGroup, careProvider } = this.props;

    if (!profile) {
      this.props.fetchProfile();
    }
    this.props.fetchHealthInfo();
    this.props.checkIfQuestionnaireDue();
    this.props.checkIfPendingItem();
    this.props.getChecklistItems();

    if (!clinic) {
      this.props.fetchClinic();
    }

    if (!recoveryGroup) {
      this.props.fetchRecoveryGroup();
    }

    if (!careProvider) {
      this.props.fetchCareProvider();
    }

    if (getUrlVars(window.location.href)["user_id"]) {
      this.props.fetchActionItems(
        false,
        getUrlVars(window.location.href)["user_id"]
      );
    } else {
      this.props.fetchActionItems(false);
    }
  };

  render() {
    let {
      appointments,
      careProvider,
      chats,
      checklistItems,
      clinic,
      contentLoaded,
      drugTestDetails,
      errors,
      expandedAppt,
      isFetching,
      nextCourse,
      npsDue,
      primaryDiagnosis,
      primaryProgram,
      recoveryGroup,
      success,
      user,
    } = this.props;

    const isOud = permissions.isOud(user, primaryDiagnosis);
    const isClinic = permissions.isClinic(user, primaryDiagnosis);
    const hasRecoveryGroup = primaryProgram?.offers_recovery_groups;

    const userIdFromUrl = getUrlVars(window.location.href)["user_id"];

    return (
      <div className="Patient-App-Container">
        {user && (
          <div>
            <Nav />

            {npsDue && <NetPromoterScore />}

            {dates.upcomingHoliday(clinic?.holidays, 3) && (
              <DashboardBanner info="holiday" />
            )}

            <div
              id="App-Body-Content"
              className="App-Appointments-Index"
              role="main"
            >
              {contentLoaded && (
                <PatientChat
                  coachName={"Counselor"}
                  userIsClinic={permissions.isClinic(user)}
                  cta={permissions.isCore(user)}
                  chatSessionId={
                    getUrlVars(window.location.href)["chat_session_id"]
                  }
                  color="#1A7A65"
                />
              )}

              {!contentLoaded ? (
                <LoadingSpinner />
              ) : (
                <div className="App-Main-Page">
                  <h1 className="page-header">My Workit Dashboard</h1>

                  <div className="App-Tabs">
                    <div className="column">
                      <Tab
                        title="Action Items"
                        subtitle={
                          permissions.isCoach(user)
                            ? ""
                            : "Appointments and To Dos"
                        }
                        key="0"
                        className="action-items"
                      >
                        <ActionItems />
                      </Tab>

                      {/* {isClinic && hasRecoveryGroup && (
                        <Tab
                          title="Support Group"
                          key="1"
                          className="recovery-groups"
                        >
                          <RecoveryGroups />
                        </Tab>
                      )} */}
                    </div>

                    <div id="middle-column" className="column">
                      <Tab title={"Courses"} key="2" className="next-exercise">
                        <NextCourse />
                      </Tab>
                      {primaryProgram?.requires_drug_test && (
                        <Tab
                          title="Drug Testing"
                          key="1"
                          className="drug-testing"
                        >
                          <DrugTesting history={this.props.history} />
                        </Tab>
                      )}
                    </div>

                    <div className="column">
                      <Tab title="My Care Team" key="3" className="care-team">
                        <div className="dashboard-block">
                          {chats &&
                            [
                              "Admin Team",
                              "Medical Team",
                              "Counselor Team",
                            ].map((chatTeam, i) => {
                              const teamChat = chats.find(
                                (chat) => chat.other.full_name === chatTeam
                              );
                              if (!teamChat) return null;
                              return (
                                <MessageShortcut
                                  key={i}
                                  chat={teamChat}
                                  other={teamChat.other}
                                />
                              );
                            })}

                          {this.maybeRenderCareProvider()}
                        </div>
                      </Tab>

                      {/* <Tab title="Community" key="4" className="community">
                        <div className="dashboard-block">
                          <a
                            href="https://www.workithealth.com/community/"
                            target="_blank"
                            className="block-item no-border"
                          >
                            <div className="details">
                              <p>Upcoming Events</p>
                            </div>

                            <img
                              src={getIconUrl("chevron-right", "dark-blue")}
                            />
                          </a>
                        </div>
                      </Tab> */}
                    </div>
                  </div>

                  {expandedAppt && <AppointmentModal />}
                  {drugTestDetails && <DrugTestingModal />}
                </div>
              )}
            </div>
          </div>
        )}

        <style jsx>{appointmentTabStyles}</style>
        <style jsx>{dashboardBlockStyles}</style>

        {/* disable buttons if admin is viewing */}
        <style global jsx>{`
          #App-Body-Content button,
          #App-Body-Content a {
            pointer-events: ${!!userIdFromUrl ? "none" : ""};
          }
        `}</style>
      </div>
    );
  }

  maybeRenderCareProvider = () => {
    let { chats, careProvider } = this.props;
    let cpInChats = false;
    if (!chats || !careProvider) {
      return;
    }

    chats.map((chat, i) => {
      if (chat.other?.id === careProvider.id && chat.other?.model === "admin") {
        cpInChats = true;
      }
    });

    if (cpInChats) {
      return;
    }

    const mutatedHash = {
      full_name: careProvider.fir_name,
      model: "admin",
      id: careProvider.id,
      pic: careProvider.profile_pic,
      title: "Primary Care Provider",
      role: "Provider",
    };

    return <MessageShortcut other={mutatedHash} />;
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
